import React, { useState, useRef, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/all';
import Section from '../Section/Section';
import HtmlParser from '../HtmlParser/HtmlParser';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';

gsap.registerPlugin(ScrollTrigger);

const Wrapper = styled.div` 
  position: relative;
  width: 100%;
  height: 100vh;
  background: #383B23;
  overflow: hidden;
  border-radius: 0 0 40px 40px;
` 

const Inner = styled.div` 
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 130px 0 0;
`

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`

const Head = styled.div` 

`

const BottomContent = styled.div` 
  flex: 1;
  position: relative;
`

const TextWrapper = styled(Section)` 
  position: relative;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  height: 100%;
`

const TextContent = styled.ol` 
  margin: 50px 0 0; 
  padding: 0;
  list-style: none;
  counter-reset: li;
  position: relative;

  & > li {
    padding: 0 80px 0 30px;
    counter-increment: li;
  }

  & > li:before {
    ${fontstack.default}
    ${type('body')}
    content: counter(li) ". ";
    display: block;
    position: absolute;
    width: 30px;
    margin-left: -30px;
  }

  &:last-child{
      margin-bottom: 0;
  }
`

const TextContentSection = styled.li` 
  color: #F3EFD7;
  position: absolute;
  opacity: ${props => props.active ? '1' : '0'};
  transition: opacity .6s ease;
`

const Title = styled.h2`
  ${fontstack.default}
  ${type('body')}
  color: #F3EFD7;
  padding: 0 0 20px;
  margin: 0;
  text-transform: uppercase;
  
`

const Divider = styled.div`
  position: relative;
  width: 100%;
  height: 1px;
  padding: 0 30px;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: #F3EFD7;
  }
`
const Items = styled.div`
  
`

const Images = styled.ul`
  list-style: none;
  margin: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  padding: 0;
`

const ImageWrapper = styled.li` 
  box-sizing: border-box;
  padding: 0 30px;
  width: 100%;
  height: 100%;
  position: absolute;

  &:nth-child(1) {
    transform: translate(0%,0);
  }

  &:nth-child(2) {
    transform: translate(100%,0);
  }

  &:nth-child(3) {
    transform: translate(200%,0);
  }
`

const ImageWrapperInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const WeInteractive = (props) => {
  const { data } = props;
  const [ CurrentChecked, setCurrentChecked ] = useState('1');
  const [ Active, setActive ] = useState(0); 
  const ImagesEl = useRef([]);
  const ImagesInnerEl = useRef([]);
  const [ MainWrapper, setMainWrapper ] = useState();
  const Controller = useRef();

  useEffect(() => {

    if(MainWrapper) {
      Controller.current = ScrollTrigger.create({
        trigger: MainWrapper,
        start: "top top",
        anticipatePin: 1,
        end: `+=${window.innerHeight*3}`,
        pin: true,
        onUpdate: onPinnedScrollUpdate
      });  
    }
    
  return () => {
    if(Controller.current) {
      Controller.current.kill();
    }
  }
  }, [MainWrapper]);

  const onPinnedScrollUpdate = (self) => {

    const progress = self.progress;
  
    if(progress <= 0.33) {
      setActive(0)
    } else if(progress > 0.33 && progress <= 0.66) {
      setActive(1)
    } else {
      setActive(2)
    }

    ImagesEl.current.forEach((image, index)=> {

      const position = (index * 100) - (progress*(100*3));
      const rotation = position;

      const innerEl = ImagesInnerEl.current[index];

      gsap.to(image, 
        { 
          duration: 3,
          ease: 'elastic.out(.8, 0.4)',
          x: position + '%'
        }
      );

      gsap.to(innerEl, 
        { 
          duration: 3,
          ease: 'elastic.out(.8, 0.4)',
          rotation: rotation
        }
      );
    })
  }

  const onChange = (value) => {
    const progress = (value / 3) - (1/3);
    const scrollPos = Controller.current.start + progress * (Controller.current.end - Controller.current.start);
    Controller.current.scroll(scrollPos);
  }

  const MainWrapperRef = useCallback((node) => {
    if( node !== null ) {
      setMainWrapper(node);
    }
  },[])
  
  return (
    <Wrapper ref={MainWrapperRef}>
      <Inner>
        <Content>
          <Head>
            <Section none contain><Title>Our Approach</Title></Section>
          </Head>
          <Divider />
          <BottomContent>
            <TextWrapper none>
              <TextContent>
              { data ? data.map((object, index) => {
               return <TextContentSection active={Active === index ? true : false}>
                  <HtmlParser>
                    {object.copy.html}
                  </HtmlParser>
                </TextContentSection>
               }) : null}
              </TextContent>
            </TextWrapper>
            <Images>
            { data ? data.map((object, index) => {
               return <ImageWrapper ref={(ref)=>ImagesEl.current[index] = ref}>
               <ImageWrapperInner>
                 <WeImage src={object.circle_image.url} placeholder={{
                   src: `${object.circle_image.url}&w=100&blur=10&q=50`,
                   width: object.circle_image.dimensions.width,
                   height: object.circle_image.dimensions.height
                 }} alt={object.circle_image.alt} ref={(ref)=>ImagesInnerEl.current[index] = ref}/>
               </ImageWrapperInner>
             </ImageWrapper>
               }) : null}
            </Images>
          </BottomContent>     
        </Content>
        <Items>
          <WeItems name="interactive" callback={onChange}>
            { data ? data.map((object, index) => {
               return <WeItem value={`${index+1}`} checked={Active === index? true : false}>{object.label}</WeItem>
            }) : null}
           
          </WeItems>
        </Items>
      </Inner>
    </Wrapper>
  )
}

export default WeInteractive;

const LabelWrapper = styled.label`
  ${fontstack.default}
  ${type('large')}
  font-weight: 300;
  color: #F3EFD7;
  text-transform: uppercase;
  padding: 20px 30px;
  border-top: 1px solid #F3EFD7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  user-select: none;

  &:not(:last-child) {
    border-right: 1px solid #F3EFD7;
  }
`

const Label = styled.h4`
  font-weight: 300;
  margin: 0;
  opacity: 0.4;
  transition: opacity .2s ease;
`

const Dot = styled.div` 
  border-radius: 50%;
  border: 1px solid #F3EFD7;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.4;
  transition: all .2s ease;
`

const Input = styled.input`
  display: none;

  &:checked ~ ${Label},
  &:checked ~ ${Dot} {
    opacity: 1;
  }

  &:checked ~ ${Dot} {
    color: #383B23;
    background: #F3EFD7;
  }
`

const OrderedList = styled.div`
  padding: 0;
  display: flex;
`



const WeItems = (props) => {
  const { children, name, callback } = props;

  const onChange = (event) => {
    const value = event.target.value;
    if(callback) {
      callback(value);
    }
  }

  const radios = React.Children.map(children, child => {
    if(React.isValidElement(child)) {
      return React.cloneElement(child,{ name:name, onChange: onChange});
    }
    return child;
  });

  return (
    <OrderedList>
      { radios }
    </OrderedList>
  )
}

export { WeItems };

const WeItem = (props) => {
  const { value, children, theme, ...rest } = props;

  return (
    <LabelWrapper>
      <Input type="radio" value={value} {...rest}/>
      <Label>{children}</Label>  
      <Dot>{value}</Dot> 
    </LabelWrapper>
  )

}

export { WeItem }


const WeImageWrapper = styled.div` 
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  overflow: hidden;
  border-radius: 50%;
`
const WeImageTag = styled.img` 
  height: 100%;
  width: auto;
  position: relative;
`

const WeImage = React.forwardRef((props,ref) => {
  const { src, placeholder, alt, ...rest } = props;
  return (
    <WeImageWrapper ref={ref} {...rest}>
      <WeImageTag src={src} alt={alt} data-pin-no-hover/>
    </WeImageWrapper>
  )
})