import React, { useEffect, useState } from "react"
import { Link, graphql } from "gatsby"
import styled from 'styled-components';
import { Seo } from "../components/seo";
import { StoreContext } from "../context/store-context";
import { Layout } from "../components/Layout/layout"
import Bracket from "../components/Bracket/Bracket";
import Section from "../components/Section/Section";
import WeSection from "../components/We/WeSection";
import WeInteractive from "../components/We/WeInteractive";
import WeInteractiveMobile from "../components/We/WeInteractiveMobile";


export default function WePage({
  data: { we },
}) {
  const { width } = React.useContext(StoreContext);
  const weSections = we.data.we_sections;
  const ourApproach = we.data.our_approach;
  return (
    <Layout background='transparent' navBorder>
      <Seo
          title='We'
          description='We make fast functional foods with Nature to deliver nutritious pleasures conveniently.'
        />
      <Bracket bottom bottomShift zIndex={2} background='#F2EADE' nopad={ width && width < 1080 ? true : false }>
        { weSections ? weSections.map((section)=> {

          return <WeSection 
            label={section.title} 
            statement={section.statement.html}
            src={section.image.url}
            alt={section.image.alt}
            placeholder={{
              src: `${section.image.url}&w=100&blur=10&q=50`,
              width: section.image.dimensions.width,
              height: section.image.dimensions.height
            }}
          />
        }) : null }
      </Bracket>
      { width && width >= 1080 ? <WeInteractive data={ourApproach} /> : width && width < 1080 ? <WeInteractiveMobile data={ourApproach}/> : null }
    </Layout>
  )
}

export const query = graphql`
  query {
    we: prismicWe {
      data {
        we_sections {
          title
          statement {
            html
          }
          image {
            alt
            dimensions {
              height
              width
            }
            url
          }
        }
        our_approach {
          label
          copy {
            html
          }
          circle_image {
            alt
            dimensions {
              height
              width
            }
            url
          }
        }
      }
    }
  }
`