import React from 'react';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';
import Section from '../Section/Section';
import ImageBlock from '../ImageBlock/ImageBlock';
import HtmlParser from '../HtmlParser/HtmlParser';

const TopWrapper = styled.div` 
  position: relative;
  display: grid;
  grid-template-columns: repeat(12,1fr);
  padding: 0 0 60px;
  border-bottom: 1px solid#383B23;

  ${media.large`
    padding: 0 0 120px;
  `}
`

const BottomWrapper = styled.div` 
  position: relative;
  display: grid;
  grid-template-columns: repeat(12,1fr);
  margin: 15px 0 0;

  ${media.large`
    margin: 30px 0 0;
  `}
`

const LabelWrapper = styled.div`
  grid-column: 1 / span 12;
  ${media.large`
    grid-column: 1 / span 4;
  `}
`

const Label = styled.h2` 
  ${fontstack.default}
  ${type('caption')}
  text-transform: uppercase;
  color: #383B23;
  margin: 0 0 15px;
  ${media.large`
    ${type('body')}
    margin: 0;
  `}
`

const StatementWrapper = styled.div` 
  grid-column: 1 / span 12;
  ${media.large`
    grid-column: 5 / span 7;
  `}

`

const Statement = styled.blockquote`
  ${fontstack.default}
  ${type('m-large')}
  font-weight: 300;
  color: #383B23;
  margin: 0;

  ${media.large`
    ${type('large')}
    font-weight: 300;
    text-indent: 120px;
  `}
`

const ImageWrapper = styled.div` 
  grid-column: 1 / span 12;
  border-radius: 20px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  ${media.large`
    grid-column: 5 / span 4;
  `}
`

const WeSection = (props) => {
  const { label, statement, src, placeholder, alt } = props;
  return (
    <Section large contain>
      <TopWrapper>
        <LabelWrapper>
          <Label>{label}</Label>
        </LabelWrapper>
        <StatementWrapper>
          <Statement><HtmlParser strip={['p']}>{statement}</HtmlParser></Statement>
        </StatementWrapper>
      </TopWrapper>
      <BottomWrapper>
        <ImageWrapper>
          <ImageBlock src={src} placeholder={placeholder} alt={alt}/>
        </ImageWrapper>
      </BottomWrapper>
    </Section>

  )
}

export default WeSection;