import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/all';
import Section from '../Section/Section';
import HtmlParser from '../HtmlParser/HtmlParser';
import ImageBlock from '../ImageBlock/ImageBlock';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';

import NatureImage from './assets/nature.jpg';
import NatureBlurImage from './assets/nature_blur.jpg';
import NurtureImage from './assets/nurture.jpg';
import NurtureBlurImage from './assets/nurture_blur.jpg';
import YouImage from './assets/you.jpg';
import YouBlurImage from './assets/you_blur.jpg';

gsap.registerPlugin(ScrollTrigger);


const OuterWrapper = styled.div` 
  position: relative;
  width: 100%;
  height: auto;
  padding: 0 0 100px;
  background: #383B23;
  overflow: hidden;
  border-radius: 0 0 40px 40px;

`

const Wrapper = styled.div` 
  position: relative;
  width: 100%;
  height: auto;
  background: #383B23;
  overflow: hidden;
` 

const Inner = styled.div` 
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`

const Head = styled.div` 
  padding: 140px 0 0;
  position: relative;
  z-index: 2;
  background: #383B23;
`

const BottomContent = styled.div` 
  flex: 1;
  position: relative;
  z-index: 1;
`

const TextWrapper = styled(Section)` 
  position: relative;
`

const TextContentSectionContent = styled.div` 
  position: relative;
  margin: 20px 0 20px;
`

const TextContent = styled.ol` 
  padding: 0;
  list-style: none;
  counter-reset: li;
  position: relative;

  & > li {
    counter-increment: li;
    position: relative;
  }

  & > li:before {
    content: '';
    display: block;
    width: 100%;
    height: 40px;
  }

  & > li > ${TextContentSectionContent}:before {
    ${fontstack.default}
    ${type('m-body')}
    content: counter(li) ". ";
    display: block;
    position: relative;
    top: 0;
    margin: 0 0 20px;
  }

  &:last-child{
      margin-bottom: 0;
  }
`

const TextContentSection = styled.li` 
  color: #F3EFD7;
  position: relative;
  margin: 0;
`

const ImageSpacer = styled.div` 
  position: relative;
  width: 100%;
  padding-bottom: 100%;
`

const Title = styled.h2`
  ${fontstack.default}
  ${type('caption')}
  color: #F3EFD7;
  margin: 0 0 20px;
  text-transform: uppercase;
`

const Items = styled.div`
  display: grid;

`

const Images = styled.ul`
  list-style: none;
  margin: 0;
  position: relative;
  width: 100%;
  top: 0;
  z-index: 0;
`

const ImageWrapper = styled.div` 
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  position: relative;
  transition: opacity .2s ease;
`

const ImageWrapperInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const WeInteractiveMobile = (props) => {
  const { data } = props;
  const [ CurrentChecked, setCurrentChecked ] = useState('1');
  const [ Active, setActive ] = useState(0); 
  const ContentMarkers = useRef([]);
  const Triggers = useRef([]);
  const ImagesEl = useRef([]);
  const ItemsEl = useRef([]);
  const ImagesInnerEl = useRef([]);
  const [ MainWrapper, setMainWrapper ] = useState();
  const [ HeadEl, setHeadEl ] = useState();
  const Controller = useRef();
  const Controller2 = useRef();
  const ImagesContainerEl = useRef();

  useEffect(() => {

    if(MainWrapper && HeadEl) {
      setTimeout(()=>{
        Controller.current = ScrollTrigger.create({
          trigger: MainWrapper,
          endTrigger: ContentMarkers.current[2],
          start: "80px top",
          end: `top +=150`,
          pin: HeadEl,
          markers: false,
          onUpdate: onPinnedScrollUpdate
        });
      }, 1000)
      
    }
      

       /*Controller2.current = ScrollTrigger.create({
        trigger: MainWrapper.current,
        start: "80px top",
        end: `bottom bottom`,
        pin: ImagesContainerEl.current
      });*/
 
     return () => {
       if(Controller.current) {
        Controller.current.kill();
       }

    
        
        //Controller2.current.kill();
     }
  }, [MainWrapper, HeadEl]);

  const MainWrapperReference = useCallback((node) => {
    if(node !== null) {
      setMainWrapper(node);
    }
  },[])

  const HeadElReference = useCallback((node) => {
    if(node !== null) {
      setHeadEl(node);
    }
  },[])

  const useContentMarkers = (index) => {

    const setRefs = useCallback(
      (node) => {
        if(node !== null) {
          ContentMarkers.current[index] = node;
          Triggers.current.push(ScrollTrigger.create({
            trigger: node,
            start: "top +=150",
            end: `bottom top`,
            markers: false,
            onEnter: () => {
              gsap.set(node.label, {opacity: "1"});
            },
            onLeaveBack: () => {
              if(index !== 0) {
                gsap.set(node.label, {opacity: "0"});
              }
              
            }
          }));
          if(ContentMarkers.current.length === 3) {
            const contentMarkers = ContentMarkers.current;
            contentMarkers.forEach((marker,index) => { 
              marker.content = ImagesEl.current[index];
              marker.label = ItemsEl.current[index];
              //gsap.set(ImagesEl.current[index], {transformOrigin: "center"});

              marker.content.enter = () => {
                //gsap.set(marker.content, {opacity: "1"});
              }

            })
          }
          }
      },
      [ContentMarkers.current],
    );


    return [setRefs];
  }


  const onPinnedScrollUpdate = (self) => {
    const currScroll = window.scrollY;
    let newContent;
    const contentMarkers = ContentMarkers.current;

    contentMarkers.forEach((marker,index) => { 
      const boundingBox = marker.getBoundingClientRect();
      //console.log(marker.offsetTop);
      if(currScroll > (boundingBox.top + currScroll) - 100 && currScroll < (boundingBox.top + currScroll) + 120) {
        newContent = marker.content;
        console.log(marker);
        gsap.set(newContent, {opacity:'1'});
      } else {
        newContent = marker.content;
        //gsap.set(newContent, {opacity:'0'});
      }

      /*if(currScroll > (boundingBox.top + currScroll)) {
        gsap.set(marker.label, {opacity: "1"});
      } else {
        if(index !== 0) {
          gsap.set(marker.label, {opacity: "0"});
        }
       
      }*/
    });

    if(newContent) {
      //newContent.enter();
    }
    


    /*
    const progress = self.progress;
  
    if(progress <= 0.33) {
      setActive(0)
    } else if(progress > 0.33 && progress <= 0.66) {
      setActive(1)
    } else {
      setActive(2)
    }

    ImagesEl.current.forEach((image, index)=> {

      const position = (index * 100) - (progress*(100*3));
      const rotation = position;

      const innerEl = ImagesInnerEl.current[index];

      gsap.to(image, 
        { 
          duration: 3,
          ease: 'elastic.out(.8, 0.4)',
          x: position + '%'
        }
      );

      gsap.to(innerEl, 
        { 
          duration: 3,
          ease: 'elastic.out(.8, 0.4)',
          rotation: rotation
        }
      );
    })*/
  }

  const onChange = (value) => {
    const progress = (value / 3) - (1/3);
    const scrollPos = Controller.current.start + progress * (Controller.current.end - Controller.current.start);
    Controller.current.scroll(scrollPos);
  }

  const [ ref0 ] = useContentMarkers(0);
  const [ ref1 ] = useContentMarkers(1);
  const [ ref2 ] = useContentMarkers(2);
  
  return (
    <OuterWrapper>
      <Wrapper ref={MainWrapperReference}>
        <Inner>
          <Content>
            <Head ref={HeadElReference}>
              <Section none style={{zIndex:10, background: '#383B23'}}>
                <Title>Our Approach</Title>
                <Items>
                { data ? data.map((object, index) => {
                  return <WeItem value={`${index+1}`} ref={(ref)=>ItemsEl.current[index] = ref}>{object.label}</WeItem>
                }) : null}
                </Items>
              </Section>
            </Head>
            <BottomContent>
              <TextWrapper none>
                <TextContent>
                { data ? data.map((object, index) => {
                  let theRef;

                  if(index === 0) {
                    theRef = ref0;
                  } else if (index === 1) {
                    theRef = ref1;
                  } else {
                    theRef = ref2;
                  }
                return <TextContentSection key={index} ref={theRef} active={Active === index ? true : false}>
                  <ImageWrapper ref={(ref)=>ImagesEl.current[index] = ref}>
                    <ImageWrapperInner>
                      <WeImage src={object.circle_image.url} placeholder={{
                        src: `${object.circle_image.url}&w=100&blur=10&q=50`,
                        width: object.circle_image.dimensions.width,
                        height: object.circle_image.dimensions.height
                      }} alt={object.circle_image.alt} ref={(ref)=>ImagesInnerEl.current[index] = ref}/>
                    </ImageWrapperInner>
                  </ImageWrapper>
                  <TextContentSectionContent>
                    <HtmlParser>
                        {object.copy.html}
                      </HtmlParser>
                  </TextContentSectionContent>
                  </TextContentSection>
                }) : null}
                </TextContent>
              </TextWrapper>
            </BottomContent>     
          </Content>
        </Inner>
      </Wrapper>
    </OuterWrapper>
  )
}

export default WeInteractiveMobile;

const LabelWrapper = styled.label`
  ${fontstack.default}
  ${type('m-large')}
  font-weight: 300;
  color: #F3EFD7;
  text-transform: uppercase;
  padding: 12px 0;
  border-top: 1px solid #F3EFD7;
  border-bottom: 1px solid #F3EFD7;
  background: #383B23;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  user-select: none;
  grid-area: 1 / 1;
  opacity: 0;
  transition: opacity .2s ease;

  &:first-child {
    opacity: 1;
  }
`

const Label = styled.h4`
  font-weight: 300;
  margin: 0;
`

const Dot = styled.div` 
  border-radius: 50%;
  border: 1px solid #F3EFD7;
  background: #F3EFD7;
  color: #383B23;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .2s ease;
`

const Input = styled.input`
  display: none;

  &:checked ~ ${Label},
  &:checked ~ ${Dot} {
    opacity: 1;
  }
`

const OrderedList = styled.div`
  padding: 0;
  display: flex;
`



const WeItems = (props) => {
  const { children, name, callback } = props;

  const onChange = (event) => {
    const value = event.target.value;
    if(callback) {
      callback(value);
    }
  }

  const radios = React.Children.map(children, child => {
    if(React.isValidElement(child)) {
      return React.cloneElement(child,{ name:name, onChange: onChange});
    }
    return child;
  });

  return (
    <OrderedList>
      { radios }
    </OrderedList>
  )
}

export { WeItems };

const WeItem = React.forwardRef((props,ref) => {
  const { value, children, theme, ...rest } = props;

  return (
    <LabelWrapper ref={ref}>
      <Input type="radio" value={value} {...rest}/>
      <Label>{children}</Label>  
      <Dot>{value}</Dot> 
    </LabelWrapper>
  )

});

export { WeItem }


const WeImageWrapper = styled.div` 
  width: 100%;
  position: relative;
  right: 0;
  top: 0;
  overflow: hidden;
  border-radius: 50%;
  padding-bottom: 100%;
`
const WeImageTag = styled.img` 
  width: 100%;
  position: absolute;
`

const WeImage = React.forwardRef((props,ref) => {
  const { src, placeholder, alt, ...rest } = props;
  return (
    <WeImageWrapper ref={ref} {...rest}>
      <WeImageTag src={src} alt={alt}/>
    </WeImageWrapper>
  )
})